import * as React from "react";

// markup
export default function SectionBuy() {
  return (
    <div className="sectionBuy" id="sectionBuy">
      <div className="about">
        <h1>Koupit</h1>
        <h3>
          K dispozici máme připraveny dvě varianty, jedna lepší než druhá! Navíc
          nyní za speciální předprodejové ceny.
        </h3>
        <div className="about_texts">
          <div className="text">
            <div className="card">
              <div className="header">
                <h1>KOMIKS</h1>
              </div>
              <div className="cardText">
                <ol></ol>
                Ideální varianta pro každého komiksového, ale i nekomiksového
                fanouška, kteý se chce ponořit do nového originálního světa
                Nanits Chronicles. Pořádná nálož 400 stránkového komiksu, který
                na Vaší poličce zabere více jak 3 centimetry prostoru.
                Samozřejmostí jsou i bonusy, které ukazují postupy práce,
                nepoužité, nebo nerealizované obálky a také i výběr koncept
                artu.
                <br />
                <br />
                Součástí vydání jsou i dvě naprosto nové kapitoly, které nikdy
                před tím nebyly vydány.
                <br />
              </div>
              <a
                href="https://www.epocha.cz/kniha/robert-kalocai-nanits-chronicles-prvni-vydani-vazana-s-prebalem/"
                target="_blank"
                className="button"
                rel="noreferrer"
              >
                <h3>699 CZK</h3>
              </a>
              {/* <span className="note">Akční cena je platná jen do 2.6.2021</span> */}
            </div>
            <div className="card">
              <div className="header">
                <h1>KOMIKS + ARTBOOK</h1>
              </div>
              <div className="cardText">
                <ol></ol>
                Speciální kombo pro každého kdo se chce naprosto ponořit do
                světa Nanits Chronicles. Balíček obsahuje kromě 400 stránkového
                komiksu i speciální 100 stránkový designový artbook, který
                detailně popisuje tvorbu postav, prostředí, techniky, zbraní,
                ale i oblečení, které má možnost čtenář v komiksu spatřit.
                Správna volba pro komiksové nadšence.
                <br />
                <br />
                Pozor! Artbook vychází pouze v limitované (1000 ks) ručně
                číslované edici, které se znovu nebude opakovat.
                <br />
              </div>
              <a
                href="https://www.epocha.cz/kniha/robert-kalocai-nanits-chronicles-limitovany-art-book-prvni-vydani-vazana-s-prebalem/"
                target="_blank"
                className="button"
                rel="noreferrer"
              >
                <h3>998 CZK</h3>
              </a>
              {/* <span className="note">Akční cena je platná jen do 2.6.2021</span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
