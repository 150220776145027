import * as React from "react";

// markup
export default function SectionTwo() {
  return (
    <div className="sectionTwo" id="sectionTwo">
      <div className="about">
        <h1>O knize</h1>
        <h3>
          Nanits Chronicles je originální komiksový příběh z blízké, ale i
          mnohem vzdálenější budoucnosti, který sleduje osudy tří hrdinů na
          pozadí mnohem větších událostí zásadně formujících budoucnost planety
          Země.
        </h3>
        <div className="about_texts">
          <div className="text">
            <p>
              <span className="date">2056</span> <br />
              Westernové postapo, kde Harry, ostřílený pistolník ze Scarova
              gangu, žije ve světě trhaném na kusy smrtící pandemií. Všechno se
              zkomplikuje v okamžiku, kdy gang cestuje do Santa Fe, aby přivezl
              cenný náklad. Něco se ale pokazí. Něco se strašně pokazí a Harryho
              život se obrátí vzhůru nohama.
            </p>
            <p>
              <span className="date">2177</span> <br />
              Čistokrevné sci-fi, ve kterém je nákaza, jež sužovala svět na
              planetě Zemi, minulostí díky sloučenině NANITS. Lidstvo povstalo z
              úpadku a znovu vzkvétá. Z Marsu, který existuje jako svobodná
              republika nezávislá na Zemi, přilétá delegace, jejíž členkou je i
              mladá Marťanka Nia. Ta se zaplete do spiknutí, které ohrožuje
              život nejen jí, ale i osud všech obyvatel planety Země.
            </p>
            <p>
              <span className="date">2225</span> <br />
              Špinavá cyberpunková detektivka, ve které se do temných zákoutí
              černého trhu vydává detektiv Frinkle, zlomený muž poháněný hněvem
              a touhou po pomstě. Jeho jediným cílem je ochromení černého trhu a
              výroby nebezpečné napodobeniny NANITS. Cesta za odhalením toho,
              kdo za výrobnou napodobeniny stojí, se ukáže jako mnohem
              nebezpečnější, než se na první pohled zdálo.
            </p>
          </div>
        </div>
        <br />
        <div className="sample1"></div>
        <br />
        <h1>Doporučení</h1>
        <div className="about_texts">
          <div className="text">
            <p className="recomend">
              „Nanits nastavuje nový standard tvorby komiksu. Nestává se často,
              aby hned v úvodních dílech seriálu byl představen tak rozsáhlý a
              promyšlený LOR příběhu, ke všemu tak dokonale výtvarně zpracován
              do dechberoucího celku.“
              <br />
              <br />
              <span className="name">Petr Kopl</span>
            </p>
            <p className="recomend">
              „Velký příběh, za kterým je vidět velká práce. Tohle je něco, co
              chcete mít v knihovničce a číst stále dokola.“
              <br />
              <br />
              <span className="name">František Kotleta</span>
            </p>
          </div>
        </div>
        <h1>Další hodnocení najdete na:</h1>
        <div className="about_texts">
          <div className="text">
            <p className="rating">
              <a
                href="https://comicsdb.cz/comics/8739/nanits-chronicles-"
                target="_blank"
                rel="noreferrer"
              >
                <div className="cdb"></div>
              </a>
              <a
                href="https://www.databazeknih.cz/knihy/nanits-chronicles-472369"
                target="_blank"
                rel="noreferrer"
              >
                <div className="databaze-knih"></div>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
