import * as React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

// markup
export default function Menu() {
  return (
    <div className="menu">
      <div className="container">
        <div className="controls">
          <AnchorLink to="/#hero" className="logo" />
          <AnchorLink to="/#sectionTwo" className="button" title="O knize" />
          <AnchorLink to="/#gallery" className="button" title="Ukázky" />
          <AnchorLink
            to="/#sectionOne"
            className="button"
            title="Příběh Nanits"
          />
          <AnchorLink to="/#sectionThree" className="button" title="Tiráž" />
        </div>
        <div className="icons">
          <a
            href="https://www.facebook.com/nanitsofficial"
            target="_blank"
            rel="noreferrer"
          >
            <div className="facebook"></div>
          </a>
          <a
            href="https://nanitsofficial.tumblr.com"
            target="_blank"
            rel="noreferrer"
          >
            <div className="tumblr"></div>
          </a>
          <a
            href="https://twitter.com/nanitsofficial"
            target="_blank"
            rel="noreferrer"
          >
            <div className="twitter"></div>
          </a>
          <a
            href="https://www.instagram.com/nanitsofficial"
            target="_blank"
            rel="noreferrer"
          >
            <div className="instagram"></div>
          </a>
          <a
            href="https://www.youtube.com/channel/UCd3BgavGNsXTu0Q72Jid8uA"
            target="_blank"
            rel="noreferrer"
          >
            <div className="youtube"></div>
          </a>
          <a
            href="https://comicsdb.cz/comics/8739/nanits-chronicles-"
            target="_blank"
            rel="noreferrer"
          >
            <div className="cdb"></div>
          </a>
        </div>
      </div>
    </div>
  );
}
