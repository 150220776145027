import * as React from "react";
// import Swiper JS
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
// import Swiper styles
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination]);

// markup
export default function SectionArtbook() {
  return (
    <div className="boxArtbook" id="gallery">
      <div className="aboutGal">
        <div className="galleryBox">
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            pagination
            loop={true}
            navigation
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            <SwiperSlide>
              <div className="jedna"></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="dva"></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="tri"></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="ctyri"></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="pet"></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sest"></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="sedm"></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="osm"></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="devet"></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="deset"></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="jedenact"></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="dvanact"></div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="trinact"></div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}
