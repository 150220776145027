import * as React from "react";
import "../styles/styles.scss";
import Hero from "../components/hero";
import SectionOne from "../components/section_one";
import Footer from "../components/footer";
import SectionTwo from "../components/section_two";
import SectionThree from "../components/section_three";
import SectionFour from "../components/section_four";
import SectionBuy from "../components/section_buy";
import SectionArtbook from "../components/section_artbook";

// markup
export default function IndexPage() {
  return (
    <div className="web">
      <Hero />
      <div className="webContent">
        <SectionTwo />
        <SectionFour />
        <SectionBuy />
        <SectionArtbook />
        <SectionOne />
        <SectionThree />
        <Footer />
      </div>
    </div>
  );
}
