import * as React from "react";

// markup
export default function SectionOne() {
  return (
    <div className="section" id="sectionOne">
      <div className="about">
        <h1>Příběh Nanits</h1>
        <h3>Vážená čtenářko, vážený čtenáři,</h3>
        <div className="about_texts">
          <div className="text">
            <p>
              Rádi bychom vám pomocí těchto řádků krátce popsali dlouhou
              historii projektu, jež nemusí být na první pohled zřejmá a pro
              někoho zcela známá.
              <br />
              <br />
              Příběh knihy, začínal jako sen, touha, přání natočit vlastní velký
              sci-fi film, který se zrodil v hlavně Roberta Kaločaie. Svět
              budoucnosti, v němž se tento film měl odehrávat, psal někdy od
              roku 2006. Velmi rychle Robert ale zjistil, že realizovat takovou
              představu je v českých podmínkách nesnadné, nebo lépe řečeno
              rovnou nemožné. Svého snu se ale vzdát nechtěl. Proto hledal to
              nejbližší možné médium, které k filmu vůbec může být. Volba padla
              zcela jasně na komiks. <br />
              <br />
              Vzhledem k tomu, že se chtěl Robert zaměřit hlavně na tvorbu svého
              světa, hrdinů a jejich příběhů v něm, chtěl najít někoho
              talentovaného, kdo bude schopný přenést jeho představy do reality.
              Jestli to byla náhoda, nebo osud, každopádně velice rychle narazil
              na člověka, jenž s ním začal tvořit druhou část kreativního týmu.
              Po prvním setkání s Michaelem Petrusem vznikla dvojce, která se
              velmi snadno doplňovala nejen v představách a vizích světa Nanits
              Chronicles, ale také ve zpracování a distribuci samotného komiksu.
            </p>
            <p>
              Kreativní partneři se rozhodli svůj komiks distribuovat digitálně
              a dokázali společně vizi původního filmového formátu přesunout do
              komiksového média tak skvěle, že se rozhodli celý projekt posunout
              mnohem dál. Vznikla digitální aplikace, která umožňovala číst
              digitální komiks tak, jak si oba autoři představovali, že by v 21.
              století mělo být běžné. Komiks, který se bude scrollovat, bude
              obsahovat originální hudbu a zvuky, jež bude plynule reagovat na
              obsah scén, stejně jako ve filmu. Zároveň bude forma ctít principy
              vyprávění tradičního komiksového média. Časem aplikace, již
              nazvali prostě jen NANITS, neobsahovala pouze jejich vlastní
              komiks Nanits Chronicles, ale také dalších více než 100 komiksů od
              autorů z celého světa. <br />
              <br />
              Jejich naděje na úspěch s novou formou komiksu ale skončily
              odchodem investorů z projektu, který se tak ze dne na den
              zastavil. Stejně jako se Robert na začátku nechtěl vzdát svého
              snu, tak se nyní s Michaelem nechtěli vzdát své práce a možnosti
              dále vyprávět své příběhy. Spojili své úsilí s Nakladatelstvím
              Epocha, které jim pomohlo dokončit další dvě nákladné kapitoly z
              již rozpracovaných příběhů.  <br />
              <br />
              Toto spojení umožnilo vydání této knihy v ucelené podobě tak, jak
              ji její autoři zamýšleli.
            </p>
          </div>
          <br />
          <div className="sample2"></div>
          <br />
          <h1>Autoři</h1>
          <div className="text">
            <p>
              <div className="photoBob"></div>
              <span className="author">
                <h3>Robert Kaločai</h3>
              </span>
              Roberta provází celoživotní vášeň k velkým příběhům, originálním
              světům a zajímavým hrdinům. Díky filmům Jamese Camerona, Stevena
              Spielberga nebo Ridleyho Scotta, na nichž vyrůstal, se stal sci-fi
              žánr jeho nejoblíbenější příběhovou oblastí, kterou chtěl jednou
              sám prozkoumat. Od základní školy se věnuje uměleckým směrům od
              kresby přes malbu, keramiku až po design produktů. Podstatnou část
              svého života se věnuje také natáčení dokumentů, krátkometrážních
              filmů a znělek. Živil se jako střihač upoutávek a trailerů, kdy se
              mimo jiné dostal i k tvorbě trailerů na obnovené premiéry filmů
              Alien a Aliens v kinech. Projekt Nanits Chronicles je jeho
              srdcovým projektem, kterému se postupně věnuje již od roku 2006.
            </p>
            <p>
              <div className="photoMichael"></div>
              <span className="author">
                <h3>Michael Petrus</h3>
              </span>
              Michael je esencí ilustrátor a komiksový kreslíř. Kreslení na
              volné noze se profesionálně věnuje od roku 2008 a je držitelem
              několika mezinárodních ocenění. Má na svém kontě řadu komerčních i
              autorských projektů a produkci několika komiksových knih, včetně
              svého fantasy románu On the Coast of Dreams. Zároveň se však živí
              jako creative director v ilustrátorském a animá-torském studiu
              artBLOCK, které spoluzaložil v roce 2013 a skrze které pomáhá
              firmám odlišit se atraktivní vizuální komunikací a marketingem.
              Kromě mentoringu kreativních freelancerů tvoří Michael pořad
              Kreativní Kreatury o životě a podnikání na kreativní volné noze.
              Je fanouškem moderních technologií, kreslení na iPadu a milovníkem
              legendární rockové kapely Foo Fighters. 
            </p>
          </div>
          <div className="sample3"></div>
        </div>
      </div>
    </div>
  );
}
