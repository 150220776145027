import * as React from "react";

// markup
export default function SectionThree() {
  return (
    <div className="sectionThree" id="sectionThree">
      <div className="about">
        <h1>Tiráž</h1>
        {/* <h3>Sub-header</h3> */}
        <div className="about_texts">
          <div className="text">
            <div className="parameter">
              <div className="title">
                <span className="nameStyle">Název knihy:</span>
                <br />
                Nanits Chronicles
              </div>
              <br />
            </div>
            <div className="parameter">
              <div className="title">
                <span className="nameStyle">Počet stran: </span>
                <br />
                400
              </div>
            </div>
            <div className="parameter">
              <div className="title">
                <span className="nameStyle">Vydalo: </span>
                <br />
                Nakladatelství Epocha s.r.o.
              </div>
            </div>
            <div className="parameter">
              <div className="title">
                <span className="nameStyle">Grafická úprava: </span>
                <br /> Robert Kaločai
              </div>
            </div>
            <div className="parameter">
              <div className="title">
                <span className="nameStyle">Odpovědný redaktor: </span>
                <br />
                Václav Kotrman
              </div>
            </div>
            <div className="parameter">
              <div className="title">
                <span className="nameStyle">Jazykové korektury: </span>
                <br />
                Martin Ďásek, Václav Kotrman
              </div>
            </div>
            <div className="parameter">
              <div className="title">
                <span className="nameStyle">ISBN:</span>
                <br />
                978-80-278-0026-1 (PRINT)
              </div>
            </div>
            <div className="parameter">
              <div className="title">
                <span className="nameStyle">Vytiskly: </span>
                <br />
                Těšínské papírny s.r.o.
              </div>
            </div>
          </div>
          <div className="text">
            <div className="parameter">
              <div className="title">
                <span className="nameStyle">Příběh: </span>
                <br /> Robert Kaločai
              </div>
            </div>
            <div className="parameter">
              <div className="title">
                <span className="nameStyle">Scénáře: </span>
                <br />
                Robert Kaločai, František Kašpar, Petr Smazal, Viktor Šauer
              </div>
            </div>
            <div className="parameter">
              <div className="title">
                <span className="nameStyle">Kresby: </span>
                <br />
                Felipe Arciniegas, Gabriel Ibarra Nuñez, Puste, Brian Terrero
              </div>
            </div>
            <div className="parameter">
              <div className="title">
                <span className="nameStyle">Barvy: </span>
                <br />
                Daniel Rosa Durán, Ondřej Hrdina, Michael Petrus
              </div>
            </div>
            <div className="parameter">
              <div className="title">
                <span className="nameStyle">Lettering: </span>
                <br /> Miloš Mičátek
              </div>
            </div>
            <div className="parameter">
              <div className="title">
                <span className="nameStyle">Concept Art: </span>
                <br /> Jozef Boža, Jan Doležálek, Mergen Erdenebayar, Brian
                Terrero
              </div>
            </div>
            <div className="parameter">
              <div className="title">
                <span className="nameStyle">Art Direkce: </span>
                <br /> Michael Petrus
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
