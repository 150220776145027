import * as React from "react";

// markup
export default function Footer() {
  return (
    <div className="footer-body">
      <div className="copyright">
        COPYRIGHT © 2002 - 2021 , NAKLADATELSTVÍ EPOCHA S.R.O.
      </div>
      <div className="copyright">
        ALL RIGHTS RESERVED © 2015 - 2021 <br />
        NANITS ® UNIVERSE LTD.
      </div>
    </div>
  );
}
